@font-face {
  font-family: "Poppins-SemiBold";
  src: url('./fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: "Poppins-Regular";
  src: url('./fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: "Poppins-Medium";
  src: url('./fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: "Poppins-Bold";
  src: url('./fonts/Poppins-Bold.ttf');
}